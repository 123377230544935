import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Input } from 'reactstrap';

const CustomRadioButton = (props) => {
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const {
    children,
    hideFormError,
    name: propsName,
    clearError,
    shouldUnregister,
    type,
    arrayCheckbox,
    fieldValue,
    ...inputProps
  } = props;

  return (
    <>
      <Controller
        name={propsName}
        control={control}
        shouldUnregister={shouldUnregister}
        render={({ field: { onChange, onBlur, value } }) => (
          <Input
            type={type}
            onBlur={onBlur}
            {...inputProps}
            checked={value === fieldValue}
            onChange={(event) => {
              onChange(event?.target?.value);
              if (clearError) {
                clearErrors();
              }
            }}
          />
        )}
      />
      {!hideFormError ? (
        <ErrorMessage
          errors={errors}
          name={propsName}
          render={({ message }) => (
            <small className="text-danger">{message}</small>
          )}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default CustomRadioButton;
