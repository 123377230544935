import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

const ConfirmationModal = (props) => {
  const {
    closeModal,
    confirmBtnText,
    confirmationText,
    handleConfirm,
  } = props;

  return (
    <Modal isOpen toggle={closeModal} centered>
      <ModalHeader toggle={closeModal} />
      <ModalBody>
        <div className="text-center">
          <p>{confirmationText}</p>
        </div>
        <div className="modal-action--row">
          <Button color="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button color="danger" onClick={handleConfirm}>
            {confirmBtnText || 'Confirm'}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmationModal;
