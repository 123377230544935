import React from 'react';

const Loader = () => (
  <div className="loader-tenant">
    <div className="la-square-jelly-box">
      <div />
      <div />
    </div>
  </div>
);

export default Loader;
