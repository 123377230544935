import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';

const DatePickerField = (props) => {
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const {
    children,
    hideFormError,
    name: propsName,
    clearError,
    shouldUnregister,
    type,
    handleOnChange,
    ...inputProps
  } = props;

  return (
    <>
      <Controller
        name={propsName}
        control={control}
        shouldUnregister={shouldUnregister}
        render={({ field: { onChange, onBlur, value } }) => (
          <DatePicker
            selected={value}
            onBlur={onBlur}
            {...inputProps}
            onChange={(event) => {
              if (handleOnChange) {
                handleOnChange(event);
              }
              onChange(event);
              if (clearError) {
                clearErrors();
              }
            }}
          />
        )}
      />
      {!hideFormError ? (
        <ErrorMessage
          errors={errors}
          name={propsName}
          render={({ message }) => (
            <small className="text-danger">{message}</small>
          )}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default DatePickerField;
