import React from 'react';

const HandyMan = (props) => (
  <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <ellipse cx="4.55859" cy="34.9385" rx="0.878906" ry="0.878907" fill="#1369E9" />
    <path d="M38.4826 12.1054C38.2427 11.927 37.9298 11.8822 37.6485 11.9877L32.7662 13.8202C32.3118 13.9907 32.0815 14.4978 32.2529 14.9522C32.4234 15.4066 32.9306 15.6369 33.385 15.4664L36.8777 14.155C36.179 17.3086 33.9158 19.9637 30.8291 21.1309C28.8797 21.8683 26.7923 21.9597 24.7928 21.3946C23.5131 21.0334 22.1622 21.3665 21.2657 22.2647L6.7875 36.7429C6.24873 37.2808 5.5333 37.5779 4.77217 37.5779C3.20068 37.5779 1.92188 36.2991 1.92188 34.7267C1.92188 33.9656 2.21807 33.2502 2.75684 32.7114L17.2667 18.1998C18.1931 17.2734 18.5288 15.926 18.1421 14.6841C17.9522 14.0751 17.8934 13.8044 17.8758 13.6971C17.1032 9.15406 19.6758 4.67251 23.993 3.04126C27.0621 1.88111 30.5057 2.38384 33.116 4.29195L26.3687 6.84166C26.1507 6.92427 25.974 7.08951 25.8782 7.3022C25.7824 7.5149 25.7745 7.7566 25.8571 7.97457C25.8571 7.97457 28.9184 16.0754 28.9641 16.1976L29.8966 18.6647C30.0688 19.1191 30.5769 19.3476 31.0295 19.1762C31.4839 19.0048 31.7124 18.4968 31.541 18.0433L30.6085 15.5762L30.5549 15.5964C30.394 15.0093 29.9669 13.8782 29.0546 11.4647L27.8118 8.17584L35.1278 5.40992C35.4082 5.30357 35.6147 5.06275 35.6771 4.76919C35.7387 4.47564 35.6473 4.17154 35.4346 3.96148C32.2626 0.830815 27.5279 -0.175533 23.3716 1.39595C18.2695 3.32339 15.2294 8.62144 16.1426 13.9907C16.1883 14.2614 16.2929 14.6586 16.4634 15.2053C16.655 15.8223 16.4871 16.4929 16.0239 16.9561L1.51406 31.4686C0.643066 32.3387 0.164062 33.4962 0.164062 34.7267C0.164062 37.2685 2.23125 39.3357 4.77217 39.3357C6.00264 39.3357 7.16016 38.8567 8.03027 37.9857L22.5067 23.5066C22.9611 23.0513 23.6344 22.8931 24.3138 23.0856C26.6789 23.7536 29.146 23.6455 31.4496 22.7745C35.6262 21.196 38.5248 17.3085 38.8342 12.871C38.8544 12.573 38.7226 12.2847 38.4826 12.1054Z" fill="#1369E9" />
    <path d="M24.1213 14.2086C24.225 14.2086 24.3296 14.1902 24.4324 14.1515L26.0768 13.5301C26.5312 13.3587 26.7597 12.8516 26.5884 12.3972C26.417 11.9428 25.9072 11.7152 25.4554 11.8857L23.811 12.5071C23.3566 12.6785 23.1281 13.1856 23.2995 13.64C23.4322 13.9915 23.7662 14.2086 24.1213 14.2086Z" fill="#1369E9" />
    <path d="M25.6559 9.93117C25.4845 9.47678 24.9765 9.24826 24.523 9.41877L22.8785 10.0402C22.4242 10.2115 22.1948 10.7187 22.3661 11.1731C22.4989 11.5246 22.8337 11.7417 23.1888 11.7417C23.2925 11.7417 23.3971 11.7233 23.4991 11.6846L25.1435 11.0632C25.5988 10.8927 25.8282 10.3856 25.6559 9.93117Z" fill="#1369E9" />
    <path d="M25.0538 16.6749C25.1575 16.6749 25.2621 16.6565 25.3649 16.6178L27.0093 15.9964C27.4637 15.825 27.6922 15.3179 27.5208 14.8635C27.3495 14.4091 26.8415 14.1815 26.3879 14.352L24.7435 14.9734C24.2891 15.1448 24.0606 15.6519 24.232 16.1063C24.3647 16.4587 24.6987 16.6749 25.0538 16.6749Z" fill="#1369E9" />
  </svg>

);
export default HandyMan;
