import React from 'react';

const LoaderRound = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto',
    }}
    width="25px"
    height="25px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle cx="50" cy="50" fill="none" stroke="#e1e1e1" strokeWidth="10" r="28" strokeDasharray="131.94689145077132 45.982297150257104">
      <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
    </circle>
  </svg>
);

export default LoaderRound;
