import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

const TextAreaField = (props) => {
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const {
    children,
    hideFormError,
    name: propsName,
    clearError,
    shouldUnregister,
    ...inputProps
  } = props;

  return (
    <>
      <Controller
        name={propsName}
        control={control}
        shouldUnregister={shouldUnregister}
        render={({ field: { onChange, onBlur, value } }) => (
          <textarea
            name={propsName}
            onBlur={onBlur}
            onChange={(event) => {
              onChange(event);
              if (clearError) {
                clearErrors();
              }
            }}
            value={value}
            {...inputProps}
          />
        )}
      />
      {!hideFormError ? (
        <ErrorMessage
          errors={errors}
          name={propsName}
          render={({ message }) => (
            <small className="text-danger">{message}</small>
          )}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default TextAreaField;
