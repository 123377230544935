export const setLocalStorage = (key, value, append) => {
  let stringValue = value;
  if (typeof value === 'object') {
    stringValue = JSON.stringify(value);
    if (append) {
      stringValue = JSON.stringify({
        ...getLocalStorage(key),
        ...value,
      });
    }
  }

  localStorage.setItem(key, stringValue);
};

export const getLocalStorage = (key) => {
  const storageItem = localStorage.getItem(key);
  try {
    return JSON.parse(storageItem);
  } catch (err) {
    return storageItem;
  }
};

export const scrollToError = (formErrors, reverse) => {
  const elements = (Object.keys(formErrors)
  || []).map((name) => document
    .getElementsByName(name)[reverse ? document.getElementsByName(name).length - 1 : 0]);
  const validElements = (elements || []).filter((el) => !!el);
  validElements[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
};
