import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import NumberField from 'react-number-format';

const CustomNumberField = (props) => {
  const {
    control,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const {
    children,
    name: propsName,
    getFormattedValue,
    clearError,
    hideFormError,
    ...inputProps
  } = props;

  return (
    <>
      <Controller
        name={propsName}
        control={control}
        render={({ field }) => (
          <NumberField
            name={propsName}
            onValueChange={(currentValue) => {
              field?.onChange(currentValue.value);
              if (clearError) {
                clearErrors();
              }
            }}
            {...inputProps}
            onChange={(e) => {
              if (getFormattedValue) {
                field?.onChange(e.target.value);
              }
              if (clearError) {
                clearErrors();
              }
            }}
            value={field?.value}
          />
        )}
      />
      {!hideFormError ? (
        <ErrorMessage
          errors={errors}
          name={propsName}
          render={({ message }) => (
            <small className="text-danger">{message}</small>
          )}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default CustomNumberField;
