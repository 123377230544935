import { isObject, isEmpty } from 'lodash';

const catchHandler = (error, setError, setFieldError) => {
  if (isObject(error?.data?.errors) && !isEmpty(error?.data?.errors)) {
    Object.keys(error.data.errors).forEach((field) => {
      setError(setFieldError ? [field]?.toString() : 'general_errors', {
        type: 'manual',
        message: error?.data?.errors[field] || error?.data?.errors[field][0],
      });
    });
  } else if (!isEmpty(error?.data?.message)) {
    setError('general_errors', {
      type: 'manual',
      message: error?.data?.message,
    });
  }
};

export default catchHandler;
